<template>
  <div class="page layout-vertical" v-if="punch">
    <div class="padding padding-large font-size-medium layout-inflexible">
      <span class="clickable font-color-primary" @click="$router.back()">打卡列表</span>
      <span class="margin-left-small">/ {{ punch.title }}</span>
    </div>
    <ys-infinite-scroll-view class="layout-flexible">
      <div
          class="layout-vertical layout-center"
      >
        <div>
          <div class="card padding-horizontal-large padding-vertical margin-top" style="min-width: 400px; width: 50vw;">
            <div class="font-weight-bold">打卡信息</div>
            <div class="divider margin-vertical"></div>
            <div class="padding-vertical">
              <span>打卡图标：</span>
              <ys-image-picker
                  class="image-icon"
                  :image.sync="punch.image"
                  @update:image="handlePunchImageUpdate"
              ></ys-image-picker>
            </div>
            <div class="padding-vertical">
              <span>标题：</span>
              <ys-click-to-edit
                  class="font-size-large"
                  ref="inputTitle"
                  v-model="punch.title"
                  single-click-to-edit
                  :maxlength="4"
                  placeholder="请输入打卡标题"
                  @input="handlePunchTitleInput"
              >
              </ys-click-to-edit>
              <span class="margin-left font-color-primary fas fa-edit"
                    @click="$refs.inputTitle.focus()"
              ></span>
            </div>
          </div>
          <div class="margin-top-large margin-bottom layout-vertical font-size-small">
            <span>子打卡项目：</span>
          </div>
          <ys-movable @move="handlePunchItemMove">
            <div class="punch-item-list layout-vertical">
              <ys-movable-item
                  v-for="punchItem in punch.punchItems"
                  :key="punchItem.id"
                  :tag="punchItem.globalId"
              >
                <div class="punch-item list-item layout-horizontal layout-middle">
                  <ys-image-picker
                      class="image-icon"
                      :image.sync="punchItem.image"
                      @update:image="handlePunchItemImageUpdate(punchItem)"
                  ></ys-image-picker>
                  <div class="margin-left">
                    <ys-click-to-edit
                        class="input-punch-item-title font-size-large font-align-center"
                        single-click-to-edit
                        :maxlength="4"
                        v-model="punchItem.title"
                        @input="handlePunchItemTitleInput(punchItem)"
                    ></ys-click-to-edit>
                  </div>
                  <div
                      class="btn-edit-sheet margin-left"
                      :class="{
                      'font-color-danger': punchItem.sheetIds.length == 0
                       }"
                      @click="clickEditSheet(punchItem)"
                  >
                    <span class="fas fa-pen-alt"></span>
                    <span v-if="punchItem.sheetIds.length > 0"> 编辑表单</span>
                    <span v-else>请添加表单</span>
                  </div>
                  <div class="layout-flexible"></div>
                  <div>
                    <ys-button
                        class="btn-delete"
                        round
                        type="secondary"
                        lighting
                        @click="clickDeletePunchItem(punchItem)"
                    >
                      <span class="far fa-trash-alt font-color-danger"></span>
                    </ys-button>
                  </div>
                  <div class="font-color-placeholder margin-left">
                    <span class="fas fa-angle-right"></span>
                  </div>
                </div>
              </ys-movable-item>
              <div
                  class="punch-item-add padding padding-large layout-vertical layout-center layout-middle font-size-large font-color-light-placeholder"
                  @click="clickAddItem"
              >
                + 添加子打卡项
              </div>
            </div>
          </ys-movable>
        </div>
      </div>
    </ys-infinite-scroll-view>
    <sheet-edit-dialog
        :visible.sync="sheetEditDialogVisible"
        :sheet-list="sheetList"
        @change="handleSheetListChange"
    ></sheet-edit-dialog>
    <delete-dialog message="您确定要删除该打卡子项吗" :visible.sync="deletePunchItemDialogVisible"
                   @confirm="handleDeletePunchItemConfirm"></delete-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import PunchUtils from "@/assets/javascript/punch-utils";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsInput from "@/components/wedigets/YsInput";
import YsImagePicker from "@/components/wedigets/YsImagePicker";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsMovable from "@/components/wedigets/YsMovable";
import YsMovableItem from "@/components/wedigets/YsMovableItem";
import YsButton from "@/components/wedigets/YsButton";
import SheetEditDialog from "@/components/sheet/SheetEditDialog";
import TextUtils from "@/assets/javascript/text-utils";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import GuidUtils from "@/assets/javascript/guid-utils";
import OrganizationUtils from "@/assets/javascript/organization-utils";

/**
 * 提交打卡
 */
const TASK_SUBMIT_PUNCH = 0;
/**
 * 提交打卡子项
 */
const TASK_SUBMIT_PUNCH_ITEM = 1;
/**
 * 删除打卡子项
 */
const TASK_DELETE_PUNCH_ITEM = 2;
/**
 * 设置打卡子项的排序位置
 */
const TASK_SET_PUNCH_ITEM_ORDER = 3;

export default {
  name: "Punch",
  components: {
    DeleteDialog,
    SheetEditDialog,
    YsButton, YsMovableItem, YsMovable, YsClickToEdit, YsImagePicker, YsInput, YsInfiniteScrollView
  },
  mixins: [httpapi, AutosubmitHelper],
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 常量
       */
      PunchUtils: PunchUtils,

      /**
       * 组织id
       */
      organizationId: null,

      /**
       * 打卡id
       */
      punchId: null,

      /**
       * 打卡
       */
      punch: null,

      /**
       * 编辑表单弹窗
       */
      sheetList: [],
      sheetEditDialogVisible: false,

      currentPunchItem: null,
      deletePunchItemDialogVisible: false,
    }
  },
  activated() {
    this.organizationId = OrganizationUtils.$getOrganization().id;
    if (this.$route.query.id) {
      this.punchId = this.$route.query.id;
      this.loadPunch();
    } else {
      this.punch = PunchUtils.defaultPunch();
      this.punch.title = '新建打卡';
    }
  },
  deactivated() {
    this.sheetEditDialogVisible = false;
  },
  methods: {
    handlePunchTitleInput: function () {
      this.createSubmitPunchTask();
    },
    handlePunchImageUpdate: function () {
      this.createSubmitPunchTask();
    },
    clickAddItem: function () {
      let punchItem = PunchUtils.defaultPunchItem();
      punchItem.title = '打卡子项';
      punchItem.globalId = GuidUtils.guid();
      this.punch.punchItems.push(punchItem);
      this.createSubmitPunchItemTask(punchItem);
    },
    handlePunchItemImageUpdate: function (punchItem) {
      this.createSubmitPunchItemTask(punchItem);
    },
    handlePunchItemTitleInput: function (punchItem) {
      this.createSubmitPunchItemTask(punchItem);
    },
    clickEditSheet: function (punchItem) {
      this.loadSheetListByPunchItem(punchItem);
    },
    handleSheetListChange: function (sheetList) {
      this.currentPunchItem.sheetList = sheetList;
      this.createSubmitPunchItemTask(this.currentPunchItem);
    },
    clickDeletePunchItem: function (punchItem) {
      this.currentPunchItem = punchItem;
      this.deletePunchItemDialogVisible = true;
    },
    handleDeletePunchItemConfirm: function () {
      this.createDeletePunchItemTask(this.currentPunchItem);
    },
    handlePunchItemMove: function (evt) {
      let fromIndex;
      let toIndex;
      for (let n = this.punch.punchItems.length - 1; n >= 0; n--) {
        if (this.punch.punchItems[n].globalId === evt.from) {
          fromIndex = n;
        } else if (this.punch.punchItems[n].globalId === evt.to) {
          toIndex = n;
        }
      }
      if (evt.direction == 'bottom') {
        toIndex++;
      }

      if (typeof toIndex === 'undefined') return;

      /*if (fromIndex < toIndex) {
        toIndex--;
      }*/
      let punchItem = this.punch.punchItems[fromIndex];
      this.punch.punchItems.splice(fromIndex, 1);
      this.punch.punchItems.splice(toIndex, 0, punchItem);

      this.createSetPunchItemOrderTask(punchItem, toIndex);
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadPunch: function () {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/punch/${this.punchId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.punch = res.data;
            for (let punchItem of this.punch.punchItems) {
              punchItem.globalId = GuidUtils.guid();
            }
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => this.busyLoadingCode = 0);
    },
    loadSheetListByPunchItem: function (punchItem) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.currentPunchItem = punchItem;
      if (punchItem.sheetList) {
        this.sheetList = punchItem.sheetList;
        this.sheetEditDialogVisible = true;
        return;
      }

      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/punch/sheet/list/${punchItem.id}`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            let sheetList = res.data;
            punchItem.sheetList = sheetList;
            this.sheetList = sheetList;
            this.sheetEditDialogVisible = true;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    createSubmitPunchTask() {
      this.createTask({
        tag: TASK_SUBMIT_PUNCH,
        params: this.punch,
        runFunc: this.submitPunch
      });
    },
    createSubmitPunchItemTask(punchItem) {
      if (!this.punch.id) {
        this.createSubmitPunchTask();
      }
      this.createTask({
        tag: TASK_SUBMIT_PUNCH_ITEM,
        params: {
          punchItem,
          punch: this.punch,
        },
        runFunc: this.submitPunchItem,
      })
    },
    createDeletePunchItemTask(punchItem) {
      for (let n = this.punch.punchItems.length - 1; n >= 0; n--) {
        if (punchItem.globalId === this.punch.punchItems[n].globalId) {
          this.punch.punchItems.splice(n, 1);
          break;
        }
      }
      this.createTask({
        tag: TASK_DELETE_PUNCH_ITEM,
        params: punchItem,
        runFunc: this.removePunchItem,
      })
    },
    createSetPunchItemOrderTask(punchItem, order) {
      if (!this.punch.id) {
        this.createSubmitPunchTask();
      }
      this.createTask({
        tag: TASK_SET_PUNCH_ITEM_ORDER,
        params: {
          punchItem,
          order,
        },
        runFunc: this.setPunchItemOrder,
      })
    },
    async submitPunch(punch) {
      if (TextUtils.isBlank(punch.title)) return;
      if (this.punch.id) {
        await this.setPunch(punch);
      } else {
        await this.addPunch(punch);
      }
    },
    async addPunch(punch) {
      await this.$reqPost({
        path: `/punch/${this.organizationId}/${encodeURIComponent(punch.title)}` + (punch.image ? `/${punch.image.id}` : '')
      })
          .returnWhenCodeMatches()
          .then(res => {
            punch.id = res.data.id;
            window.eventBus.$emit('notify:punch_add', punch.id);
          })
          .catch(() => {
            this.$message.error('保存失败');
          })
          .promise();
    },
    async setPunch(punch) {
      await this.$reqPut({
        path: `/punch/${punch.id}/${punch.title}` + (punch.image ? `/${punch.image.id}` : '')
      })
          .returnWhenCodeMatches()
          .catch(() => {
            this.$message.error('保存失败');
          })
          .promise();
    },
    async submitPunchItem({punchItem, punch}) {
      if (TextUtils.isBlank(punchItem.title)) return;
      if (!punchItem.id) {
        await this.addPunchItem(punchItem, punch);
      } else {
        await this.setPunchItem(punchItem);
      }
    },
    async addPunchItem(punchItem, punch) {
      let promise = this.$reqPostJSON({
        path: `/punch/item/${punch.id}/${encodeURIComponent(punchItem.title)}` + (punchItem.image ? `/${punchItem.image.id}` : ''),
        data: {
          sheets: punchItem.sheetList ?? [],
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            punchItem.id = res.data.id;
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
      await promise;
    },
    async setPunchItem(punchItem) {
      let response;
      if (punchItem.sheetList) {
        let path = `/punch/item/${punchItem.id}/${encodeURIComponent(punchItem.title)}` + (punchItem.image ? `/${punchItem.image.id}` : '');
        console.log('path: ' + path);
        response = this.$reqPut({
          path: `/punch/item/${punchItem.id}/${encodeURIComponent(punchItem.title)}` + (punchItem.image ? `/${punchItem.image.id}` : ''),
          data: {
            sheets: punchItem.sheetList,
          }
        })
      } else {
        response = this.$reqPatch({
          path: `/punch/item/${punchItem.id}/${encodeURIComponent(punchItem.title)}` + (punchItem.image ? `/${punchItem.image.id}` : ''),
        })
      }
      await response.then(res => {
        if (punchItem.sheetList) {
          let sheetIds = [];
          for (let n = 0; n < punchItem.sheetList.length; n++) {
            punchItem.sheetList[n].id = res.data.sheetList[n].id;
            sheetIds.push(res.data.sheetList[n].id);
          }
          punchItem.sheetIds = sheetIds;
        }
      })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async removePunchItem(punchItem) {
      console.log('delete punch item: ' + punchItem.id);
      if (!punchItem.id) return;
      await this.$reqDelete({
        path: `/punch/item/${punchItem.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            for (let n = this.punch.punchItems.length - 1; n >= 0; n--) {
              if (punchItem.id === this.punch.punchItems[n].id) {
                this.punch.punchItems.splice(n, 1);
                break;
              }
            }
          })
          .catch(() => this.$message.error('删除失败'))
          .promise();
    },
    async setPunchItemOrder({punchItem, order}) {
      await this.$reqPatch({
        path: `/punch/item/order/${punchItem.id}/${order}`
      })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }

  }
}
</script>

<style scoped>

.page {
  width: 100vw;
  height: 100vh;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.image-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.input-title {
  width: 600px;
}

.btn-edit-sheet {
  cursor: pointer;
  user-select: none;
}

.btn-edit-sheet:hover {
  color: #409EFF;
}

.input-punch-item-title {
  width: 200px;
}

.punch-item-add {
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
}

.punch-item-add:hover {
  background-color: #f1f1f1;
  color: #409EFF;
}

.punch-item .btn-delete {
  visibility: hidden;
}

.punch-item:hover .btn-delete {
  visibility: visible;
}

</style>